@use '../../../mixin' as mx;
@use '../../../setting' as st;

.information_add {
  .inner {
    .inner {
      @include mx.flex_box;
      @include mx.flex_direction_column;
      gap: 40px 0;
      section {
        min-width: 580px;
        width: 100%;
        max-width: 870px;
        margin: auto;
        &:last-of-type {
          .information_add__body {
            @include mx.border-default($color: st.$border_edit_wrap_color);
            padding: 5px;
          }
        }
      }
    }
  }
  &__body {
    margin: auto;
    font-size: 14px;
    .table {
      font-size: 100%;
      th {
        font-weight: normal;
      }
      tbody {
        tr {
          pointer-events: none;
          td {
            line-height: 1.5;
            padding: 10px 0;
          }

        }
      }
    }
  }
}
