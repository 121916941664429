@use '../../../mixin' as mx;
@use '../../../setting' as st;

.information_list {
  .main_cnt {
    section {
      .table_box {
        flex: 1;
        overflow: hidden;
        margin-top: 10px;
        .table_wrapper {
          max-height: 100%;
        }
      }
    }
  }
  .accordion {
    @include mx.max_width_max;
    &-header {
      @include mx.max_width_max;
    }
  }
  &__search {
    font-size: 14px;
    @include mx.flex_box;
    @include mx.flex_direction_column;
    gap: 1em 0;
    padding: 1.5em;
    &__row {
      @include mx.flex_box;
      @include mx.flex_container_wrap;
      gap: 1em 0.5em;
    }
    .labeled_form {
      height: calc(3em - 2px);
      &__label {
        display: inline-block;
        width: 6em;
        text-align: right;
      }
      > div {
        height: 100%;
      }
      &.free_word {
        input {
          width: clamp(207px, 36vw, 30em);
          height: 100%;
        }
      }
      .for_select {
        height: 100%;
        select {
          width: calc(10.5em + 1px);
          height: 100%;
        }
        &::after {
          top: 0;
          bottom: 0;
          margin: auto;
          transform: translateY(50%);
        }
      }
    }
    .period_form {
      .labeled_form {
        &:last-of-type {
          .labeled_form__label {
            display: none;
          }
        }
      }
    }
  }
}
