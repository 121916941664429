@use '../../mixin' as mx;

.info_conf_dialog {
  header {
    font-size: 1.5rem;
    text-align: center;
  }
  .dialog {
    header {
      border-bottom: none;
    }
    &_body {
      .inner {
        @include mx.flex_box;
        @include mx.flex_direction_column;
        gap: 40px;

        section {
          header {
            margin: 0;
            padding-top: 0;
          }
        }
      }
    }
  }
}