@use '../../../mixin' as mx;
@use '../../../setting' as *;
.responsive_checkbox {
  $box_size: 1.5em;
  font-size: 100%;
  font-family: inherit;
  width: max-content;
  @include mx.flex_box;
  @include mx.flex_align_center;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
  &.has_extra_label {
    @include mx.flex_direction_column;
    @include mx.flex_align_start;
    gap: 0.5em 0;
  }

  label {
    height: $box_size;
    @include mx.flex_box;
    @include mx.flex_align_center;
    gap: 0.5em;
    cursor: inherit;

    &::before {
      top: initial !important;
    }

    > span {
      line-height: 1;
    }
    input {
      &:checked {
        + .responsive_checkbox__check {
          background: $form_text_color;
          border: 1px solid $form_text_color;
          &::before {
            opacity: 1;
            transform: scale(1) translateY(-20%) rotate(45deg);
          }
        }
      }
      &:disabled {
        + .responsive_checkbox__check {
          &::after {
            $cover_size: calc(100% + 2px);
            position: absolute;
            display: block;
            content: "";
            width: $cover_size;
            height: $cover_size;
            background-color: #ffffffc0;
          }
        }
      }
    }
  }

  &__check {
    position: relative;
    width: $box_size !important;
    height: $box_size !important;
    border: 1px solid $form_checkbox_border_color;
    background: #fff;
    @include mx.flex_box;
    @include mx.flex_all_center;

    &::before {
      display: block;
      content: "";
      position: absolute;
      transition: all 0.2s;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      width: 0.5em;
      height: 1em;
      opacity: 0;
      transform: scale(0);
    }
  }
}
