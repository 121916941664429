@use '../../../mixin' as mx;
@use '../../../setting' as *;

.information_editor {
  $inner_padding: 5px;
  font-size: 100%;
  // min-width: 40em;
  margin: 0 auto;

  &__inner {
    @include mx.border-default($color: $border_edit_wrap_color);
    padding: $inner_padding;
  }

  &__item {
    display: flex;
    &__head {
      @include mx.flex_box;
      @include mx.flex_space_between;
      @include mx.flex_align_center;
      min-width: calc(18em - 2px);
      max-width: calc(18em - 2px);
      font-weight: bold;
      padding: 20px 15px;
      background-color: $item_head_background_color;
    }
    &__cnt {
      $padding: 20px;
      flex: 1;
      padding: calc(#{$padding} - #{$inner_padding}) $padding;
      @include mx.flex_box;
      @include mx.flex_align_center;
      width: 37vw;
      max-width: 608px;

      .input_wrap,
      .select {
        height: calc(3em - 2px);
      }
      .input_wrap{
        width: 100%;
        input {
          width: 100%;
          height: 100%;
        }
      }
      select {
        width: calc(10.5em + 1px);
      }
      > .responsive_checkbox {
        width: 100%;
      }
      &.cnt_content {
        // overflow: hidden;
        > div {
          height: 26.43em;
          border-radius: 0;
          resize: none;
        }
      }
      &.cnt_notification_url {
        @include mx.flex_direction_column;
        @include mx.flex_align_start;
        gap: 1.5em 0;
      }
    }
  }

  .date_box {
    margin-bottom: 50px;
  }
}
