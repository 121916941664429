@use '../../../mixin' as mx;
@use '../../../setting' as st;

.react_select {
  font-size: 100%;
  font-family: inherit;
  border-radius: 0;
  min-width: 13.75em;
  min-height: 3em;
  border: 1px solid st.$form_border_color;

  * {
    font-size: 100%;
    font-family: inherit;
  }

  &__form {
    height: 100%;
    min-height: inherit;
    > div {
      border: none;
      * {
        border: inherit;
      }
      &:first-of-type {
        width: 100%;
        height: 100%;
        min-height: inherit;
        border-radius: inherit;
        box-shadow: none;
        > div {
          &:first-of-type {
            cursor: pointer;
            // タグ要素
            > div {
              background-color: transparent;
              > div {
                // タグ要素 - ラベル
                &:first-of-type {
                  order: 2;
                  padding: 0 5px;
                  font-size: 100%;
                }
                // タグ要素 - ｘボタン
                &:last-of-type {
                  $size: 1.33333em;
                  order: 1;
                  width: $size;
                  @include mx.flex_box;
                  @include mx.flex_all_center;
                  padding: 0;
                  cursor: pointer;
                  color: #fff !important;

                  &:hover {
                    background-color: transparent;
                    color: currentColor;
                  }

                  &::before,
                  &::after {
                    display: block;
                    position: absolute;
                  }
                  &::before {
                    content: "";
                    width: $size;
                    height: $size;
                    border-radius: 50%;
                    background-color: st.$site_color;
                  }
                  &::after {
                    content: "\FF0B";
                    color: currentColor;
                    transform: rotateZ(45deg);
                  }
                  svg {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      // プルダウンリスト部分
      + div {
        min-width: max-content;
        width: 100%;
        z-index: 100000000; // 適当な最前級の値
      }
    }
    // テキスト入力なしの時
    &.input_hidden {
      > div {
        &:first-of-type {
          > div {
            &:first-of-type {
              cursor: pointer;
              // タグ要素
              > div {
                // inputのラップ要素
                &:last-of-type {
                  height: 0;
                  > input {
                    height: inherit;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.multi,
  &.pulldown {
    .react_select__form {
      > div {
        &:first-of-type {
          border-radius: inherit;
          border-color: st.$form_border_color;
          > div {
            // 入力側
            &:first-of-type {
            }
            // アイコン側（消去ボタン含む）
            &:last-of-type {
              width: calc(2.5em);
              cursor: pointer;
              > div,
              span {
                display: none;
              }
              &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 10px;
                margin: auto;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 6px 0 6px;
                border-color: st.$site_color transparent transparent transparent;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
  }
  &.text,
  &.editable {
    @include mx.flex_box;
    background-color: #fff;
    border: 1px solid st.$form_border_color;
    .react_select__form {
      width: auto;
      flex: 1;
      border: none;

      > div {
        // ラベルが入る側
        &:first-of-type {
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          > div {
            // 実際のラベル群のラップ要素
            &:first-of-type {
              > div {
                // プルダウンハンドル
                // NOTE: hori inputのいらない形式どれだったか確認
                // &:last-of-type {
                //   padding: 0;
                //   margin: 0 2px;
                //   height: 0;
                //   &::after {
                //     content: initial;
                //   }
                //   input {
                //     height: 0;
                //   }
                // }
              }
            }
            &:last-of-type {
              display: none;
            }
          }
        }
      }
    }
  }
  &.text {
    .react_select__icon {
      width: calc(2.5em);
      @include mx.flex_box;
      @include mx.flex_all_center;
      padding: 0;
      position: relative;

      &::before,
      &::after {
        display: block;
        position: absolute;
      }
      &::before {
        content: "";
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        background-color: st.$site_color;
      }
      &::after {
        content: "\FF0B";
        color: #fff;
      }
    }
  }

  &.form_alert {
    position: relative;
    border-color: st.$error_text_color;
    &.multi,
    &.pulldown {
      .react_select__form {
        > div {
          &:first-of-type {
            > div {
              // アイコン側（消去ボタン含む）
              &:last-of-type {
                &::before {
                  border-color: st.$error_text_color transparent transparent
                    transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  &.disabled {
    // opacity: 1;
    .react_select__icon {
      background-color: hsl(0, 0%, 95%);
    }
  }
}
