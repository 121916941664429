@charset "UTF-8";
@import "../../../setting.scss";
@import "../../../mixin.scss";

body {
    #App {
        &.for_company {
            .main_cnt {
                > header {
                    background-color: $site_color;
                }
            }
        }
        .thum {
            width: 30px;
            height: 30px;
            object-fit:cover;
            border-radius:50%;
            vertical-align: top;
            margin: 10px 10px 0 0;
        }
        .btn_help {
            width: 30px;
            height: 30px;
            object-fit:cover;
            border-radius:50%;
            vertical-align: top;
            margin: 0 10px 10px 10px;
        }
        .user_box {
            position: absolute;
            top: 49px;
            right: 10px;
            min-width: 150px;
            padding: 15px;
            border: 1px solid $site_color;
            background-color: #FFF;
            font-size: 14px;
            z-index: 2;
            li {
                display: block;
                padding: 0 5px;
                text-align: left;
                line-height: 24px;
                cursor: pointer;
                &:hover {
                    opacity: 0.75;
                }
                + li {
                    margin-top: 4px;
                }
                img {
                    margin-right: 5px;
                }
            }
       }
    }
}